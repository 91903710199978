/* eslint-disable prettier/prettier */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function ({ $axios, redirect, store }) {
  $axios.onRequest((config) => {
    // if (store.state.app.token !== "") {
    //   $axios.setToken(store.state.app.token, 'Bearer');
    // }

    $axios.setHeader('Company', store.state.app.company);

    $axios.setHeader('Locale', store.state.app.locale);

    if (config.url === "/User/RefreshToken") {
      $axios.setToken(false);
    }
  });

  $axios.onError((error) => {
    const code = parseInt(error.response && error.response.status);
    if (code === 400) {
      // redirect('/400');
    }
  });

  $axios.setHeader('Accept', 'application/json, */*');

  $axios.setHeader('Content-Type', 'application/json');

  $axios.setHeader('Company', store.state.app.company);

  $axios.setHeader('Locale', store.state.app.locale);
}
