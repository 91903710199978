/* eslint-disable prettier/prettier */
const companiesApi = '/api/companies/';
// vehicles api
const vehiclesApi = '/api/vehicles/';
const addVehiclesApi = '/api/vehicles/';
// vehicles group api
const vehiclesGroupApi = '/api/vehicleGroups/';
// drivers api
const driversApi = '/api/drivers/';
// driver groups api
const driverGroupsApi = '/api/driverGroups/';
// device
const deviceApi = '/api/v2/devices/';
// api
const companyGroupsApi = 'api/companyGroups';
const companyGroupsTreeApi = 'api/companyGroups/tree';
const vehicleGroupsIDApi = '/api/VehicleGroups/vehicles';
const driverGroupsIDApi = '/api/DriverGroups/drivers';
const usersApi = '/api/companyuser/info';
const userGroupsApi = '/api/companyRole';
const userDropdownApi = '/api/companyuser/getdropdownlist';
const updateUserApi = '/api/companyuser';
const hqusersApi = '/api/hquser/info';
const updatehqUserApi = '/api/hquser'
const rolesApi = '/api/companyRole'
const roleApi = '/api/companyrole'
const functionsApi = '/api/function'
const rolefunctionsApi = '/api/function'
const warningApi = '/api/companywarningtypes/info'
const warningTypeSettingsApi = '/api/companywarningtypes/settings'
const updatewarningApi = '/api/companywarningtypes'
const distributorApi = '/api/companies/distributorCompanies'
const uploadVehicleApi = '/api/vehicles/batchUpload'
const uploadVehicleGroupApi = '/api/vehicleGroups/batchUpload'
const uploadDriverApi = '/api/drivers/batchUpload'
const uploadDriverGroupApi = '/api/driverGroups/batchUpload'
const uploadDeviceApi = '/api/v2/devices/batchUpload'

const tBoxProviderApiV1 = '/api/option/tboxproviders';
const tBoxProviderApi = '/api/v2/option/tboxproviders';
export default {
  async getTBoxProviderV1(axios) {
    return await axios.$get(tBoxProviderApiV1);
  },
  async getTBoxProvider(axios) {
    return await axios.$get(tBoxProviderApi);
  },
  async getCompanies(axios) {
    const result = await axios.$get(companiesApi);
    return result;
  },
  async addCompanies(axios, data) {
    const result = await axios.$post(companiesApi, data);
    return result;
  },
  async updateCompanies(axios, data) {
    const result = await axios.$put(companiesApi + data.id, data);
    return result;
  },
  async deleteCompanies(axios, val) {
    const result = await axios.$delete(companiesApi + val)
    return result;
  },
  // vehicles api
  async getVehicles(axios) {
    const result = await axios.$get(vehiclesApi);
    return result;
  },
  async addVehicles(axios, data) {
    const result = await axios.$post(addVehiclesApi, data);
    return result;
  },
  async updateVehicles(axios, data) {
    const result = await axios.$put(addVehiclesApi + data.id, data);
    return result;
  },
  async deleteVehicles(axios, val) {
    const result = await axios.$delete(vehiclesApi + val)
    return result;
  },
  // vehicles group api
  async getVehiclesGroup(axios) {
    const result = await axios.$get(vehiclesGroupApi);
    return result;
  },
  async addVehiclesGroup(axios, data) {
    const result = await axios.$post(vehiclesGroupApi, data);
    return result;
  },
  async updateVehiclesGroupDetails(axios, data) {
    const result = await axios.$put(vehiclesGroupApi + data.id, data);
    return result;
  },
  async deleteVehiclesGroup(axios, val) {
    const result = await axios.$delete(vehiclesGroupApi + val);
    return result;
  },
  // drivers api
  async getDrivers(axios) {
    const result = await axios.$get(driversApi);
    return result;
  },
  async addDrivers(axios, data) {
    const result = await axios.$post(driversApi, data);
    return result;
  },
  async updateDrivers(axios, data) {
    const result = await axios.$put(driversApi + data.id, data);
    return result;
  },
  async deleteDrivers(axios, val) {
    const result = await axios.$delete(driversApi + val)
    return result;
  },
  // driver groups api
  async getDriverGroups(axios) {
    const result = await axios.$get(driverGroupsApi);
    return result;
  },
  async addDriverGroup(axios, data) {
    const result = await axios.$post(driverGroupsApi, data);
    return result;
  },
  async updateDriverGroupDetails(axios, data) {
    const result = await axios.$put(driverGroupsApi + data.id, data);
    return result;
  },
  async deleteDriverGroup(axios, val) {
    const result = await axios.$delete(driverGroupsApi + val);
    return result;
  },
  // device
  async getDevice(axios) {
    const result = await axios.$get(deviceApi);
    return result;
  },
  async addDevice(axios, data) {
    const result = await axios.$post(deviceApi, data);
    return result;
  },
  async updateDevice(axios, data) {
    const result = await axios.$put(deviceApi + data.id, data);
    return result;
  },
  async deleteDevice(axios, val) {
    const result = await axios.$delete(deviceApi + val);
    return result;
  },
  // company groups api
  async getCompanyGroups(axios) {
    const result = await axios.$get(companyGroupsApi, {
      params: {
        PageSize: -1,
        companyId: 7,
      },
    });
    return result;
  },
  async getCompanyGroupsTree(axios) {
    const result = await axios.$get(companyGroupsTreeApi);
    return result;
  },
  async addCompanyGroup(axios, data) {
    const result = await axios.$post(companyGroupsApi, data);
    return result;
  },
  async updateCompanyGroup(axios, id, data) {
    const result = await axios.$put(companyGroupsApi + '/' + id, data)
    return result;
  },
  async deleteCompanyGroup(axios, id) {
    const result = await axios.$delete(companyGroupsApi + '/' + id)
    return result;
  },
  // Vehicle Groups
  async getVehicleGroupsID(axios, id) {
    const result = await axios.$get(vehicleGroupsIDApi + '/' + id);
    return result;
  },
  async updateVehicleGroupsID(axios, id, data) {
    const result = await axios.$put(vehicleGroupsIDApi + '/' + id, data);
    return result;
  },
  // Driver Groups
  async getDriverGroupsID(axios, id) {
    const result = await axios.$get(driverGroupsIDApi + '/' + id);
    return result;
  },
  async updateDriverGroupsID(axios, id, data) {
    const result = await axios.$put(driverGroupsIDApi + '/' + id, data);
    return result;
  },
  // User
  async getUsers(axios) {
    const result = await axios.$get(usersApi);
    return result;
  },
  async getUserGroups(axios) {
    const result = await axios.$get(userGroupsApi);
    return result;
  },
  async getUserDropdownList(axios) {
    const result = await axios.$get(userDropdownApi);
    return result;
  },
  async addUser(axios, data) {
    const result = await axios.$post(updateUserApi, data);
    return result;
  },
  async updateUser(axios, data) {
    const result = await axios.$put(updateUserApi + '/' + data.id, data);
    return result;
  },
  async deleteUser(axios, val) {
    const result = await axios.$delete(updateUserApi + '/' + val);
    return result;
  },
  async getHQUsers(axios) {
    const result = await axios.$get(hqusersApi);
    return result;
  },
  async addHQUser(axios, data) {
    const result = await axios.$post(updatehqUserApi, data);
    return result;
  },
  async updateHQUser(axios, data) {
    const result = await axios.$put(updatehqUserApi + '/' + data.id, data);
    return result;
  },
  async deleteHQUser(axios, val) {
    const result = await axios.$delete(updatehqUserApi + '/' + val);
    return result;
  },
  // Role
  async getRoles(axios) {
    const result = await axios.$get(rolesApi);
    return result;
  },
  async addRole(axios, data) {
    const result = await axios.$post(roleApi, data);
    return result;
  },
  async updateRole(axios, data) {
    const result = await axios.$put(roleApi + '/' + data.id, data);
    return result;
  },
  async getFunctions(axios) {
    const result = await axios.$get(functionsApi);
    return result;
  },
  async getRoleFunctions(axios) {
    const result = await axios.$get(rolefunctionsApi);
    return result;
  },
  // Warning Type
  async getWarningTypeSettings(axios,opts) {
    return  await axios.$get(warningTypeSettingsApi,opts);
  },
  async getWarningList(axios) {
    const result = await axios.$get(warningApi);
    return result;
  },
  async updateWarningList(axios, data) {
    const result = await axios.$put(updatewarningApi, data);
    return result;
  },
  // Distributor
  async getDistributorList(axios) {
    const result = await axios.$get(distributorApi);
    return result;
  },
  async updateDistributorList(axios, data) {
    const result = await axios.$put(distributorApi, data.Code, data);
    return result;
  },

  // Upload
  async postUploadVehicle(axios, data) {
    const result = await axios.$post(uploadVehicleApi, data);
    return result;
  },
  async postUploadVehicleGroup(axios, data) {
    const result = await axios.$post(uploadVehicleGroupApi, data);
    return result;
  },
  async postUploadDriver(axios, data) {
    const result = await axios.$post(uploadDriverApi, data);
    return result;
  },
  async postUploadDriverGroup(axios, data) {
    const result = await axios.$post(uploadDriverGroupApi, data);
    return result;
  },
  async postUploadDevice(axios, data) {
    const result = await axios.$post(uploadDeviceApi, data);
    return result;
  },
}
