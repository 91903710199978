import Vue from 'vue';

const loadingMixin = {
  data() {
    return {
      dataTableShowLoading: false,
      dataTableShowLoading2: false,
    };
  },
  methods: {
    //* for tablePanel used in ExpansionPanel and TabPanel
    setDataTableShowLoading(show) {
      this.dataTableShowLoading = show;
    },
    wrapShowloading(prom) {
      this.dataTableShowLoading = true;
      prom.finally(() => {
        this.dataTableShowLoading = false;
      });
    },
    wrapShowloading2(prom) {
      this.dataTableShowLoading2 = true;
      prom.finally(() => {
        this.dataTableShowLoading2 = false;
      });
    },
  },
};

const localSearchMixin = {
  data() {
    return {
      currentPage: 1,
    };
  },
  methods: {
    pageCountHandler(p) {
      console.log(`page count ${p}, go back page 1`);
      this.currentPage = 1;
    },
  },
};

if (!Vue.__dataTable_common__) {
  Vue.__dataTable_common__ = true;
  Vue.mixin({
    mixins: [loadingMixin, localSearchMixin],
  });
}
