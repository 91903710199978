/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable object-shorthand */
/* eslint-disable prettier/prettier */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as msal from "@azure/msal-browser"


const clientId = process.env.ADB2C_CLIENTID;
const authority = process.env.ADB2C_AUTHORITY;
const tenantId = process.env.ADB2C_TENANT;
const redirectUri = process.env.VUE_APP_ADB2C_RETURN_URI;
const url = window.location.protocol + "//" + window.location.host
// const url = window.location.href;
//! if using http://myyhost/Profile will force url to m3-uat.gstsafety.com
//! so cannot use window.location.href
//! but using http://myhost is ok, will redirect to myhost/path accordingly

export default ({ app, error, $axios, $config, store }, inject) => {
  store.commit('app/get_Lang');
  app.i18n.locale = store.state.app.locale;

  const logLevel =  $config.logLevel === "Info" ?  msal.LogLevel.Info : msal.LogLevel.Trace
  console.log($config.logLevel)

  const msalConfig = {
    auth: {
      clientId: clientId,
      authority: authority,
      knownAuthorities: [`${tenantId}.b2clogin.com`],
      redirectUri: url,
      postLogoutRedirectUri: url,
    },
    cache : {
      cacheLocation:'localStorage',
      storeAuthStateInCookie: false,
    },
    request : {
      scopes: [clientId],
      extraQueryParameters: { ui_locales: app.i18n.locale, Company: store.state.app.company }
    },
    system: {
      loggerOptions: {
          logLevel: logLevel,
          loggerCallback: (level, message, containsPii) => {
              if (containsPii) {	
                  return;	
              }
              switch (level) {	
                  case msal.LogLevel.Error:	
                      console.error(message);	
                      return;	
                  case msal.LogLevel.Info:	
                      console.info(message);	
                      return;	
                  case msal.LogLevel.Verbose:	
                      console.debug(message);	
                      return;	
                  case msal.LogLevel.Warning:	
                      console.warn(message);	
                      return;	
                  default:
                      console.log(message);
                      
              }
          }
      }
    },
  }

  const msalInstance = new msal.PublicClientApplication(msalConfig);

  inject('msal', msalInstance);
  inject('signout', (msal) => {
    msal.logoutRedirect();
  })
  inject('handleLoginRedirect', (msal, clientId) => {
    return new Promise((resolve, reject) => {
        msal.handleRedirectPromise().then((tokenResponse) => {
          if(tokenResponse !== null) {
            resolve(tokenResponse)
          } else {
  
            const accounts = msal.getAllAccounts();
  
            if (accounts.length > 0) {
                msal.setActiveAccount(accounts[0]);
  
                const request = {
                    scopes: [clientId],
                    account: accounts[0],
                    extraQueryParameters: { ui_locales: app.i18n.locale, Company: store.state.app.company }
                };
  
                msal.acquireTokenSilent(request).then(tokenResponse => {
                  console.debug('acquireTokenSilent')

                    resolve(tokenResponse);
                }).catch((e) => {
                    reject(e);
                });
            } else {
                reject(new Error('no accounts found'));
            }
          }
        });
    });
  })
}
