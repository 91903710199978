/* eslint-disable */
import SettingApiService from '@/services/settings-service';
export default async function ({ app, store, route }) {
    if (route.path.toLowerCase().includes('livestream')) {
        return;
      }
  // load warning locale

    if(!store.state.app.warningsMetaInfo) {
        await app.$remoteLocales(app.$axios);

    // 2023/2/14 move to globalutil.js
    /*
    const warningLocaleResp = await SettingApiService.getWarningTypeSettings(
        app.$axios, { 'headers' : {'company': localStorage.getItem('company')}}
    );

    if (warningLocaleResp) {
        
        store.state.app.warningsMetaInfo = warningLocaleResp;
        if (warningLocaleResp.items) {
        const warningLocale = warningLocaleResp.items.reduce((prev, cur) => {
            prev[cur.warningType] = cur.warningTypeText;
            return prev;
        }, {});

        app.i18n.mergeLocaleMessage('zh-Hant', warningLocale);
        console.log('merge warnings locale');
        }
    }
    */
    }
}
