/* eslint-disable @typescript-eslint/no-unused-vars */
export default async function ({ app, store, redirect, route }) {
  // console.log('[Middleware] Check Auth');
  if (route.path.toLowerCase().includes('livestream')) {
    return;
  }
  //* ssr = true
  //* 2022/07/22
  //* only when changing route on client
  //* when enter url or refresh in browser, will not come here
  //* after login, redirecturi from adb2c , then will be called
  //* that means when ssr = true, only come here when changing route on client

  //* if already loggedin
  //* when with or without / , route.path is / in default layout, and will push /firstpage then come here
  //* when specify /Profile or /LiveInformation (found route) , default layout will push the same route, but does not come here
  //! after fix, found route wont push, so wont come here
  //* when not found route, default layout push the same route, does not come here
  //! after fix, route not found will push firstpage, so will come here

  //* ssr = false
  //* works as expected in document

  //* first time enter app, default layout not mounted yet, so store has no menu link

  //! now using ssr = false
  //! everytime will come here first
  //! and requireAuthOnInitialize in msal plugin handle every page need signin
  //! so no need to call here and in default layout

  //! 2022/07/24
  // ? if browser on, auto refresh token ?
  // ? but if browser close, will visit authorize , redirect with hash #state=

  //! 2022/07/29
  // ? use await otherwise token not set yet, and default layout will have false isAuthenticated and not call getAuth()
  let resp = null;
  try {
    resp = await app.$handleLoginRedirect(
      app.$msal,
      process.env.ADB2C_CLIENTID
    );

    const idToken = resp.idToken;
    app.$axios.setToken(idToken, 'Bearer');
    store.commit('app/set_Token', idToken);
    console.log('set token');
    if (route.path.includes('index') || route.name === null) {
      return redirect('/');
    }
    let checkRoute = false;
    const routewithoutslash =
      route.name.slice(-1) === '/' ? route.name.slice(0, -1) : route.name;
    store.state.app.menuLinks
      .filter((item) => item.active)
      .every((item) => {
        if (item.children === null) {
          //* if not found, return true to continue
          if (item.to !== routewithoutslash) {
            return true;
          }
        } else {
          item.children
            .filter((child) => child.active)
            .every((child) => {
              if (child.to !== routewithoutslash) {
                return true;
              }
            });
        }
        //* found and return false to stop
        checkRoute = true;
        return false;
      });
    //* first time, no menulinks, but default layout will be mounted
    //* default layout will then redirect to firstpage
    if (!checkRoute && store.state.app.menuLinks.length > 0) {
      //* will not happen, coz enter app has no menu
      //* and from UI nowhere to click a nonfound link
      redirect('/' + store.state.app.firstPage);
    }
  } catch (e) {
    store.commit('app/clear_Token');
    await app.$msal.loginRedirect({ scopes: [process.env.ADB2C_CLIENTID] });
  }
}
