/* eslint-disable prettier/prettier */
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import ElementLocale from 'element-ui/lib/locale'
Vue.use(VueI18n);


export default ({ app, store }) => {
  // Set i18n instance on app
  // This way we can use it in middleware and pages asyncData/fetch
  app.i18n = new VueI18n({
    locale: store.state.app.locale || 'en',
    fallbackLocale: 'en',
    messages: {
      'en': {...require('@/locales/en.json'), ...enLocale},
      // 'zh-Hans': require('@/locales/zh-Hans.json'),
      'zh-Hant': {...require('@/locales/zh-Hant.json'), ...zhLocale},
    },
    silentTranslationWarn: true
  });
  app.i18n.path = (link) => {
    if (app.i18n.locale === app.i18n.fallbackLocale) {
      return `/${link}`
    }
    return `/${app.i18n.locale}/${link}`
  }

  ElementLocale.i18n((key, value) => app.i18n.t(key, value))
}
