/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prettier/prettier */
/* eslint-disable unicorn/prefer-includes */

export default {
  set_WarningFilter:(state, value) => {
    state.warningFilter = value;
  },
  set_ProfileViewMode:(state, value) => {
    state.profileViewMode = value;
    localStorage.setItem('profileViewMode', value);
  },
  get_ProfileViewMode:(state) => {
    state.profileViewMode = localStorage.getItem('profileViewMode') === 'true';
  },
  set_Drawer: (state, value) => {
    state.drawer = value
  },
  // Drawer toggle
  set_ToggleDrawer: (state) => {
    if (!state.drawer) {
      localStorage.setItem('toggleDrawer', false);
    } else {
      state.toggleDrawer = !state.toggleDrawer;
      localStorage.setItem('toggleDrawer', state.toggleDrawer);
    }
  },
  get_ToggleDrawer: (state) => {
    if (localStorage.getItem('toggleDrawer')) {
      if (localStorage.getItem('toggleDrawer') === 'true') {
        state.toggleDrawer = true;
      } else {
        state.toggleDrawer = false;
      }
    }
  },

  // Set Lang
  set_Lang: (state, lang) => {
    state.locale = lang;
    localStorage.setItem('locale', lang);
  },
  get_Lang: (state) => {
    state.locale = localStorage.getItem('locale') || 'en';
  },
  // Set Company
  set_Company: (state, company) => {
    state.company = company;
    localStorage.setItem('company', company);
  },
  set_CompanyRole: (state, role) => {
    state.companyRole = role;
    localStorage.setItem('companyRole', role);
  },
  get_Company: (state) => {
    state.company = localStorage.getItem('company');
    state.camType = JSON.parse(localStorage.getItem('camType'));
  },
  get_CompanyRole: (state) => {
    state.companyRole = localStorage.getItem('companyRole');
  },
  set_CamType: (state, camType) => {
    state.camType = camType;
    localStorage.setItem('camType', JSON.stringify(camType));
  },

  // Set Company
  set_Companies: (state, companies) => {
    state.companies = companies;
  },

  // Auth token
  set_Token(state, token) {
    state.token = token;
    state.isAuthenticated = true
  },
  clear_Token(state) {
    state.id_token_expires_in = null
    state.refresh_token = ''
    state.isAuthenticated = false
    state.refreshingToken = false
    state.id_token = ''
    state.token_type = ''
    state.accessToken = ''
  },
  // Set user
  set_User: (state, user) => {
    state.userId = user.id;
    state.userRole = user.role;
    state.userName = user.name;
  },
  // Set default home page
  set_FirstPage: (state, page) => {
    state.firstPage = page;
  },

  // Set menu links
  set_Menu: (state, menu) => {
    state.menuLinks = menu
  },

  set_isAdmin: (state, val) => {
    state.isAdmin = val;
  },

  // Set page function
  set_Function: (state, list) => {
    state.liveStreaming = false;
    state.collision = false;
    state.historyRoute = false;
    state.historyVideo = false;
    state.processAppeal = false;
    state.submitAppeal = false;
    state.compareScore = false;
    state.showIdling = false;

    list.forEach(item => {
      if (item === 'Live Streaming') {
        state.liveStreaming = true;
      }
      if (item === 'Collision') {
        state.collision = true;
      }
      if (item === 'HistoryRoute') {
        state.historyRoute = true;
      }
      if (item === 'HistoricalVideo') {
        state.historyVideo = true;
      }
      if (item === 'ProcessAppeal') {
        state.processAppeal = true;
      }
      if (item === 'SubmitAppeal') {
        state.submitAppeal = true;
      }
      if (item === 'CompareScore') {
        state.compareScore = true;
      }
      if (item === 'Show Idling') {
        state.showIdling = true;
      }
    });
  },

  // Set global title
  set_Title: (state, val) => {
    state.title = val.name;
    localStorage.setItem('title', val.name);
  },
  get_Title: (state) => {
    state.title = localStorage.getItem('title');
  },
  // Set location
  setLocations: (state, data) => {
    state.vehicleList = data;
  },
  getVehicle: (state, data) => {
    state.getVehicle = data;
  },
  checkType: (state, data) => {
    state.checkType = data;
  },
  // for trip google api
  /*************************************/
  getPloyline: (state, data) => {
    state.getPloyline = data;
  },
  getMarker: (state, data) => {
    state.getMarker = data;
  },
  getCluster: (state, data) => {
    state.getCluster = data;
  },
  getMobileLatLng: (state, data) => {
    state.getMobileLatLng = data;
  },
  /*************************************/
};
