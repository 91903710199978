const middleware = {}

middleware['check-auth'] = require('../middleware/check-auth.js')
middleware['check-auth'] = middleware['check-auth'].default || middleware['check-auth']

middleware['getlocales'] = require('../middleware/getlocales.js')
middleware['getlocales'] = middleware['getlocales'].default || middleware['getlocales']

middleware['i18n'] = require('../middleware/i18n.js')
middleware['i18n'] = middleware['i18n'].default || middleware['i18n']

export default middleware
