/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
export default function ({
  isHMR, app, store, route, params, error, redirect
}) {
  // console.log(store.state.app.locale)
  const defaultLocale = app.i18n.fallbackLocale;
  if (isHMR) return
  const locale = params.lang || defaultLocale
  if (store.state.app.locales) {
    if (!store.state.app.locales.includes(locale)) {
      return error({ message: 'This page could not be found.', statusCode: 404 })
    }
  }
  store.commit('app/set_Lang', locale);
  app.i18n.locale = store.state.app.locale;
}
