import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _20aaf42e = () => interopDefault(import('../pages/Appeal.vue' /* webpackChunkName: "pages/Appeal" */))
const _50a84376 = () => interopDefault(import('../pages/Dashboard.vue' /* webpackChunkName: "pages/Dashboard" */))
const _25727791 = () => interopDefault(import('../pages/LiveInformation.vue' /* webpackChunkName: "pages/LiveInformation" */))
const _7a7d5faa = () => interopDefault(import('../pages/LiveStream.vue' /* webpackChunkName: "pages/LiveStream" */))
const _8fcc35cc = () => interopDefault(import('../pages/Login.vue' /* webpackChunkName: "pages/Login" */))
const _33450a19 = () => interopDefault(import('../pages/Notifications.vue' /* webpackChunkName: "pages/Notifications" */))
const _1461dd1a = () => interopDefault(import('../pages/Profile.vue' /* webpackChunkName: "pages/Profile" */))
const _2a14f3e8 = () => interopDefault(import('../pages/Suspended.vue' /* webpackChunkName: "pages/Suspended" */))
const _524ed4f8 = () => interopDefault(import('../pages/Trip.vue' /* webpackChunkName: "pages/Trip" */))
const _aae6b534 = () => interopDefault(import('../pages/TripWarningList.vue' /* webpackChunkName: "pages/TripWarningList" */))
const _0089a837 = () => interopDefault(import('../pages/GeoFencing/EntryExitLog.vue' /* webpackChunkName: "pages/GeoFencing/EntryExitLog" */))
const _c0f1f6bc = () => interopDefault(import('../pages/GeoFencing/HistoricalLocation.vue' /* webpackChunkName: "pages/GeoFencing/HistoricalLocation" */))
const _7a486350 = () => interopDefault(import('../pages/Settings/Company.vue' /* webpackChunkName: "pages/Settings/Company" */))
const _2b571882 = () => interopDefault(import('../pages/Settings/CompanyGroup.vue' /* webpackChunkName: "pages/Settings/CompanyGroup" */))
const _f400105a = () => interopDefault(import('../pages/Settings/Device.vue' /* webpackChunkName: "pages/Settings/Device" */))
const _7adc38a5 = () => interopDefault(import('../pages/Settings/Driver.vue' /* webpackChunkName: "pages/Settings/Driver" */))
const _56e9ab8a = () => interopDefault(import('../pages/Settings/DriverGroup.vue' /* webpackChunkName: "pages/Settings/DriverGroup" */))
const _adfac88c = () => interopDefault(import('../pages/Settings/Geofencing.vue' /* webpackChunkName: "pages/Settings/Geofencing" */))
const _72e57ad1 = () => interopDefault(import('../pages/Settings/HQUser.vue' /* webpackChunkName: "pages/Settings/HQUser" */))
const _63618ff0 = () => interopDefault(import('../pages/Settings/User.vue' /* webpackChunkName: "pages/Settings/User" */))
const _3e7db99e = () => interopDefault(import('../pages/Settings/UserRole.vue' /* webpackChunkName: "pages/Settings/UserRole" */))
const _b5a47302 = () => interopDefault(import('../pages/Settings/Vehicle.vue' /* webpackChunkName: "pages/Settings/Vehicle" */))
const _0688b5f0 = () => interopDefault(import('../pages/Settings/VehicleGroup.vue' /* webpackChunkName: "pages/Settings/VehicleGroup" */))
const _13e02298 = () => interopDefault(import('../pages/Warnings/SpeedingHeatMap.vue' /* webpackChunkName: "pages/Warnings/SpeedingHeatMap" */))
const _07d02f35 = () => interopDefault(import('../pages/Warnings/TripMaintenance.vue' /* webpackChunkName: "pages/Warnings/TripMaintenance" */))
const _0f8a3a6d = () => interopDefault(import('../pages/Warnings/WarningData.vue' /* webpackChunkName: "pages/Warnings/WarningData" */))
const _f865164e = () => interopDefault(import('../pages/Warnings/WarningHeatMap.vue' /* webpackChunkName: "pages/Warnings/WarningHeatMap" */))
const _497939c8 = () => interopDefault(import('../pages/Warnings/WarningVideo.vue' /* webpackChunkName: "pages/Warnings/WarningVideo" */))
const _77002223 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/Appeal",
    component: _20aaf42e,
    name: "Appeal"
  }, {
    path: "/Dashboard",
    component: _50a84376,
    name: "Dashboard"
  }, {
    path: "/LiveInformation",
    component: _25727791,
    name: "LiveInformation"
  }, {
    path: "/LiveStream",
    component: _7a7d5faa,
    name: "LiveStream"
  }, {
    path: "/Login",
    component: _8fcc35cc,
    name: "Login"
  }, {
    path: "/Notifications",
    component: _33450a19,
    name: "Notifications"
  }, {
    path: "/Profile",
    component: _1461dd1a,
    name: "Profile"
  }, {
    path: "/Suspended",
    component: _2a14f3e8,
    name: "Suspended"
  }, {
    path: "/Trip",
    component: _524ed4f8,
    name: "Trip"
  }, {
    path: "/TripWarningList",
    component: _aae6b534,
    name: "TripWarningList"
  }, {
    path: "/GeoFencing/EntryExitLog",
    component: _0089a837,
    name: "GeoFencing-EntryExitLog"
  }, {
    path: "/GeoFencing/HistoricalLocation",
    component: _c0f1f6bc,
    name: "GeoFencing-HistoricalLocation"
  }, {
    path: "/Settings/Company",
    component: _7a486350,
    name: "Settings-Company"
  }, {
    path: "/Settings/CompanyGroup",
    component: _2b571882,
    name: "Settings-CompanyGroup"
  }, {
    path: "/Settings/Device",
    component: _f400105a,
    name: "Settings-Device"
  }, {
    path: "/Settings/Driver",
    component: _7adc38a5,
    name: "Settings-Driver"
  }, {
    path: "/Settings/DriverGroup",
    component: _56e9ab8a,
    name: "Settings-DriverGroup"
  }, {
    path: "/Settings/Geofencing",
    component: _adfac88c,
    name: "Settings-Geofencing"
  }, {
    path: "/Settings/HQUser",
    component: _72e57ad1,
    name: "Settings-HQUser"
  }, {
    path: "/Settings/User",
    component: _63618ff0,
    name: "Settings-User"
  }, {
    path: "/Settings/UserRole",
    component: _3e7db99e,
    name: "Settings-UserRole"
  }, {
    path: "/Settings/Vehicle",
    component: _b5a47302,
    name: "Settings-Vehicle"
  }, {
    path: "/Settings/VehicleGroup",
    component: _0688b5f0,
    name: "Settings-VehicleGroup"
  }, {
    path: "/Warnings/SpeedingHeatMap",
    component: _13e02298,
    name: "Warnings-SpeedingHeatMap"
  }, {
    path: "/Warnings/TripMaintenance",
    component: _07d02f35,
    name: "Warnings-TripMaintenance"
  }, {
    path: "/Warnings/WarningData",
    component: _0f8a3a6d,
    name: "Warnings-WarningData"
  }, {
    path: "/Warnings/WarningHeatMap",
    component: _f865164e,
    name: "Warnings-WarningHeatMap"
  }, {
    path: "/Warnings/WarningVideo",
    component: _497939c8,
    name: "Warnings-WarningVideo"
  }, {
    path: "/",
    component: _77002223,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
