/* eslint-disable prettier/prettier */
import Vue from 'vue';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/zh-cn';
import en from 'vue2-datepicker/locale/en';

en.formatLocale.firstDayOfWeek = 1
DatePicker.locale('en', en)
// Vue.use(DatePicker)
Vue.component('date-picker', DatePicker)
